import { Injectable, inject } from "@angular/core";
import { LanguageType } from "../enums/language-type.enum";
import { TranslocoService } from "@jsverse/transloco";
import { LocalStorageService } from "./local-storage.service";

@Injectable({
  providedIn: "root",
})
export class LanguageService {
  private readonly _translocoService = inject(TranslocoService);
  private readonly _localStorageService = inject(LocalStorageService);
  private activeLanguage!: LanguageType;

  getLanguageType() {
    if (this._localStorageService.getItem("LANGUAGE_KEY")) {
      return this._localStorageService.getItem("LANGUAGE_KEY") as LanguageType;
    }
    return this.getBrowserLanguage() as LanguageType;
  }
  setLanguage(languageType: LanguageType) {
    this._localStorageService.setItem("LANGUAGE_KEY", languageType);
    this._translocoService.setActiveLang(languageType);
  }

  toggleLanguage() {
    if (this.getLanguageType() === LanguageType.EN) {
      this.setLanguage(LanguageType.TR);
    } else {
      this.setLanguage(LanguageType.EN);
    }
  }

  getBrowserLanguage() {
    return window.navigator.language.split("-")[0];
  }

  onInitilize() {
    this.activeLanguage = this.getLanguageType();
    this.setLanguage(this.activeLanguage);
  }

  get activeLanguage$() {
    return this.activeLanguage;
  }
}
